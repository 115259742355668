import React, { FC, ReactElement } from 'react'

import AppLayout from '~components/appLayout'
import SEO from '~components/seo'

const NotFoundPage: FC = (): ReactElement => (
  <AppLayout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </AppLayout>
)

export default NotFoundPage
